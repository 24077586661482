// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = {
  styles: {
    global: {
      html: {
        fontSize: '62.5%' /* Relative font size. 1px -> 0.1rem */,
      },
      p: {
        fontSize: '1.2rem',
      },
    },
  },
  fontSizes: {
    xs: '1.2rem',
    sm: '1.6rem',
    md: '2rem',
    lg: '2.4rem',
    xl: '2.8rem',
    '2xl': '3.2rem',
    '3xl': '3.6rem',
    '4xl': '4rem',
    '5xl': '4.4rem',
    '6xl': '4.8rem',
    '7xl': '5rem',
    '8xl': '5.2rem',
    '9xl': '5.4rem',
  },
  components: {
    Link: {
      baseStyle: {
        color: 'blue',
      },
    },
  },
};

export default extendTheme(theme);
